import React from "react";

import CountUp from "react-countup";

interface CounterProps {
  number: number;
  duration?: number;
}

const Counter: React.FC<CounterProps> = ({ number, duration = 100 }) => {
  return <CountUp duration={duration} className="counter" end={number} />;
};

export default Counter;
