import Image from "next/image";

import { Container, Text, Title } from "@mantine/core";
import ScrollAnimation from "react-animate-on-scroll";

import TestimonialsImage1 from "../../../public/testimonialsAvatar1.jpg";
import TestimonialsImage2 from "../../../public/testimonialsAvatar2.jpg";
import TestimonialsImage3 from "../../../public/testimonialsAvatar3.jpg";
import TestimonialsImage4 from "../../../public/testimonialsAvatar4.jpg";
import TestimonialsImage5 from "../../../public/testimonialsAvatar5.jpg";
import TestimonialsImage from "../../../public/testimonialsAvatar.jpg";

export default function Testimonials() {
  const items = [
    {
      name: "مهندس/ خالد السعدني",
      description:
        "العمل الجيد المقدم من شركة البراك نابع من فريق عمل احترافي ويعلم كل التفاصيل التي يبغاها المستثمر شكرًا لكم.",
      image: TestimonialsImage,
    },
    {
      name: "مهندس/ محمد حامد",
      description:
        "العمل الجيد المقدم من شركة البراك نابع من فريق عمل احترافي ويعلم كل التفاصيل التي يبغاها المستثمر شكرًا لكم.",
      image: TestimonialsImage5,
    },
    {
      name: "دكتور/ خالد العناني",
      description:
        "أتقدم بخالص الشكر إلى شركة البراك شركة متخصصة في إعداد دراسة الجدوى بكل تفاصيلها.",
      image: TestimonialsImage4,
    },
    {
      name: "مستر/ حسن العتيبي",
      description:
        "والله مشكورين شركة البراك والقائمين عليها على جهدكم المبذول وتفانيكم بالعمل.",
      image: TestimonialsImage3,
    },
    {
      name: "دكتور/ عبدالله ممدوح",
      description:
        "الدراسة كانت مستوفية كل شروط الجهات الداعمة، أشكركم على هذه العمل الرائع.",
      image: TestimonialsImage2,
    },
    {
      name: "مهندس/ محمد ممدوح",
      description:
        "	اليكم أطيب التحية شركة البراك.عمل مختلف وسرعة بالانجاز وجهود رائعة لتقديم أفضل ما يمكن تقديمه من تحليل الأفكار للسير وفق خطط واضحة والتي يمكن من خلالها تحقيق النجاح بمشيئة الله .",
      image: TestimonialsImage1,
    },
  ];
  return (
    <>
      <Container fluid size={"xl"} className="bg-Color3" dir="rtl">
        <div className="mx-auto max-w-screen-xl px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
          {/* <h2 className="text-center text-4xl font-bold tracking-tight text-white sm:text-5xl pb-10">
            آراء العملاء
          </h2> */}
          <Title size={"h1"} c="Color1" order={6} className="homeHead">
            آراء العملاء
          </Title>
          <Text className="!pb-10 text-center text-xl !text-Color1">
            رائيكم يهمنا، لذلك نحن نعمل على تحسين خدماتنا باستمرار، كن احد
            شركائنا وشاركنا رائيك!
          </Text>

          <div className="mt-8 [column-fill:_balance] sm:columns-2 sm:gap-6 lg:columns-3 lg:gap-8">
            {items &&
              items.map((item, index) => (
                <ScrollAnimation
                  animateOnce={true}
                  delay={(index + 1) * 100}
                  animateIn="slideInUp"
                  animateOut="slideOutUp"
                  key={item.name}
                >
                  <div key={index} className="mb-8 sm:break-inside-avoid">
                    <blockquote className="rounded-lg bg-Color1 p-6 shadow-sm sm:p-8">
                      <div className="flex items-center gap-4">
                        <Image
                          alt={item.name}
                          width={100}
                          height={100}
                          src={item.image}
                          sizes="(max-width: 480px) 5vw, (max-width: 800px) 10vw, (max-width: 2048px) 10vw, 10vw"
                          className="size-14 rounded-full object-cover"
                          loading="lazy"
                          placeholder="blur"
                          // blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                        />

                        <div>
                          <p className="mt-0.5 text-lg font-medium text-Color4">
                            {item.name}
                          </p>
                        </div>
                      </div>

                      <p className="mt-4 text-Color3">{item.description}</p>
                    </blockquote>
                  </div>
                </ScrollAnimation>
              ))}
          </div>
        </div>
      </Container>
    </>
  );
}
