import React from "react";

import { Center, Container, Flex, Loader, Text } from "@mantine/core";

const Loaders = () => {
  return (
    <>
      <Container fluid className={"pb-32"}>
        <Container size="xl" className="!h-dvh pt-40">
          <Center w={"100%"} h={"100%"}>
            <Flex
              mih={50}
              gap="md"
              justify="center"
              align="center"
              direction="column"
              wrap="wrap"
            >
              <Loader color="Color4" size="xl" />

              <Text
                fw={500}
                // c="Color3"
                ta="center"
                size="xl"
                variant="gradient"
                gradient={{ from: "Color4", to: "Color3", deg: 90 }}
              >
                جاري التحميل
              </Text>
            </Flex>
          </Center>
        </Container>
      </Container>
    </>
  );
};

export default Loaders;
