export function TrimSchema(content: string, raw: boolean = false) {
  if (raw) {
    content = JSON.parse(content);
    content = JSON.stringify(content);
  } else {
    content = content;
  }

  return content
    .replace(/https:\/\/backend.(?![^"]*wp-content)/g, "https://")
    .replace(/category/g, "studies");
}
