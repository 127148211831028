import Image from "next/image";

import client1 from "@@/clients/1.jpg";
import client2 from "@@/clients/2.jpg";
import client3 from "@@/clients/3.jpg";
import client4 from "@@/clients/4.jpg";
import client5 from "@@/clients/5.jpg";
import client6 from "@@/clients/6.jpg";
import { Grid } from "@mantine/core";

export default function Clients() {
  const clients = [
    {
      name: "Client 1",
      image: client1,
    },
    {
      name: "Client 2",
      image: client2,
    },
    {
      name: "Client 3",
      image: client3,
    },
    {
      name: "Client 4",
      image: client4,
    },
    {
      name: "Client 5",
      image: client5,
    },
    {
      name: "Client 6",
      image: client6,
    },
  ];

  const clientMap = clients.map((client, index) => (
    <Grid.Col
      className={
        "!relative !min-h-[128px] w-[200px] cursor-pointer transition-all duration-500 hover:scale-110"
      }
      key={index}
      span={{ base: 12, xs: 6, sm: 4, md: 2, lg: 2 }}
    >
      <Image
        src={client.image}
        alt={client.name}
        fill
        style={{ objectFit: "fill" }}
        sizes="(max-width: 480px) 50vw, (max-width: 800px) 50vw, (max-width: 2048px) 50vw, 33vw"
        className={"!p-2"}
        loading="lazy"
        placeholder="blur"
        blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
      />
    </Grid.Col>
  ));
  return (
    <Grid gutter={"xl"} justify="center" align="stretch">
      {clientMap}
    </Grid>
  );
}
