import { convert } from "html-to-text";

//npm i --save-dev @types/html-to-text

export default function HtmlToStr(content: any, option: number) {
  const options = {
    wordwrap: option ? option : 130,
  };
  return convert(content, options);
}
