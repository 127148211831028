import Image from "next/image";

import {
  Center,
  Container,
  Grid,
  SimpleGrid,
  Text,
  ThemeIcon,
  Title,
  rem,
} from "@mantine/core";
import { IconFileCode } from "@tabler/icons-react";
import ScrollAnimation from "react-animate-on-scroll";

import featuresImage from "../../../public/HomeAbout.svg";

const features = [
  {
    icon: IconFileCode,
    title: "دراسات جدوى اقتصادية",
    description:
      "من الخدمات المميزة المُقدمة للمستثمرين ورواد الأعـمــــــال لـــتحــقـق الأربــــاح الــمـاليــة.",
  },
  {
    icon: IconFileCode,
    title: "استشارات إدارية ومالية",
    description:
      "نسـاعد على تطويـر خطط الأعمـال المــالـــيـــة والإدارية وتحديد الفرص الاستثمارية المتاحة.",
  },
  {
    icon: IconFileCode,
    title: "خدمات نموذج الـ Pitch Deck",
    description:
      "نساعدك في الحصول على عـــــرض تقـــديمي مقنع للمستثمرين لإيصــال الفكرة بجــــاذبيــــة.",
  },
  {
    icon: IconFileCode,
    title: "دراسة وأبحاث السوق",
    description:
      "نـعمـل عـلـى جــمــع وتــحــلــيــل مــعلومـات عن السـوق وتـحـديد حجـم الطلب للخـدمـات.",
  },
  {
    icon: IconFileCode,
    title: "وضع الخطة التسويقية",
    description:
      "نساعدك على وضع استراتيجيات تسويق فعالة وتحقيق أهداف الشــركة وتــــطــويرها باستمـــرار.",
  },
  {
    icon: IconFileCode,
    title: "دراسة وتحليل المنافسين",
    description:
      "نساعدك لوضع استراتيجية قوية لدخول السوق المستهدف بناءً على تحليل شامـل للمنافسيــن.",
  },
];
const FeatuersServiceHome = () => {
  const items = features.map((feature, i) => (
    <ScrollAnimation
      animateOnce={true}
      delay={(i + 4) * 100}
      animateIn="zoomIn"
      animateOut="zoomIn"
      key={feature.title}
    >
      <div>
        <ThemeIcon
          size={44}
          radius="md"
          variant="gradient"
          gradient={{ deg: 133, from: "Color3", to: "Color2" }}
        >
          <feature.icon
            style={{ width: rem(26), height: rem(26) }}
            stroke={1.5}
          />
        </ThemeIcon>
        <Text c="Color3" fz="lg" mt="sm" fw={500}>
          {feature.title}
        </Text>
        <Text c="black" fz="sm">
          {feature.description}
        </Text>
      </div>
    </ScrollAnimation>
  ));
  return (
    <>
      <Container
        fluid
        className={
          "overflow-hidden bg-Color1Lighten20 !px-16 !py-20 xxs:!px-5 sm:!px-0 md:!px-5 lg:!px-16"
        }
      >
        <Center>
          <Title className="homeHead" c="Color3" size={"h1"} order={2}>
            خدمات الاستشارات الاقتصادية
          </Title>
        </Center>
        <Grid gutter={50}>
          <Grid.Col span={{ base: 12, md: 4 }}>
            <ScrollAnimation
              animateOnce={true}
              animateIn="bounceIn"
              animateOut="bounceOut"
              delay={100}
            >
              <Image
                src={featuresImage.src}
                alt="خدمات الاستشارات الاقتصادية"
                // height={250}
                // width={450}
                height={0}
                width={0}
                sizes="(max-width: 480px) 33vw, (max-width: 800px) 50vw, (max-width: 2048px) 50vw, 33vw"
                style={{ width: "450px", height: "auto" }}
                loading="lazy"
                placeholder="blur"
                blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
              />

              <Text c="dimmed">
                نقدم لك دراسات جدوى تفصيلية مبنية على أسس علمية مدروسة لإتخاذ
                قرار استثماري بعناية وحرص، وأيضا الدراسة معتمدة ومطابقة لكافة
                شروط الدعم التمويل
              </Text>
            </ScrollAnimation>
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 8 }} className={"pt-[125px]"}>
            <SimpleGrid cols={{ base: 2, lg: 3, md: 2, xs: 2 }}>
              {items}
            </SimpleGrid>
          </Grid.Col>
        </Grid>
      </Container>
    </>
  );
};

export default FeatuersServiceHome;
