import Image from "next/image";
import Link from "next/link";

import logo from "@@/logo.svg";
import {
  Badge,
  Button,
  Card,
  Divider,
  Grid,
  Text,
  Title,
  TitleOrder,
} from "@mantine/core";

import HtmlToStr from "@/app/_utils/HtmlToStr";

interface StudieCardProps {
  i: number;
  stud: any;
  open: Function;
  setSelectedStudie: (studie: any) => void;
  heading?: number;
}

export default function StudieCard({
  i,
  stud,
  open,
  setSelectedStudie,
  heading = 2,
}: StudieCardProps) {
  return (
    <Grid.Col span={{ base: 12, xs: 12, sm: 6, md: 6, lg: 3 }} key={i}>
      <Card
        shadow="xs"
        className={
          "group relative bg-white transition-all duration-500 hover:shadow-lg"
        }
        padding="xs"
        radius="md"
        component="article"
      >
        <Link
          href={`/${decodeURIComponent(stud.slug)}`}
          aria-label={stud.title}
          title={stud.title}
        >
          <Card.Section
            className={
              "!relative h-[50vw] bg-Color4 shadow-lg xxs:h-[50vw] sm:h-[50vw] md:h-[24vw] lg:h-[24vw] xl:h-[12vw]"
            }
          >
            {stud?.nextjs?.rate ? (
              <Badge
                className="!text-md absolute bottom-5 right-5 z-20 !text-Color3"
                color="Color1"
                size="lg"
                variant="filled"
                radius={"sm"}
              >
                معدل الفائده: {stud?.nextjs?.rate}%
              </Badge>
            ) : null}
            {/* <Overlay
              color="#000"
              opacity={0}
              zIndex={99}
              className={
                "relative z-[99] !h-[100%] !opacity-0 transition-opacity duration-500 group-hover:!opacity-70"
              }
            >
              <Center maw={"100%"} h={"100%"}>
                <IconEye size={50} className="text-Color1" />
              </Center>
            </Overlay> */}
            <Image
              src={
                stud?.featuredImage?.node?.sourceUrl
                  ? stud?.featuredImage?.node?.sourceUrl
                  : logo
              }
              alt={stud?.title ? stud?.title : "البراك لخدمات دراسات الجدوى"}
              fill
              style={{ objectFit: "fill" }}
              loading="lazy"
              sizes="(max-width: 480px) 50vw, (max-width: 800px) 33vw, (max-width: 2048px) 50vw, 100vw"
              className="relative z-10 transition-all duration-300 group-hover:grayscale"
              placeholder="blur"
              blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
            />
          </Card.Section>
          <Title
            order={heading ? (heading as TitleOrder) : 2}
            c="Color3"
            className="mb-0 min-h-[65px] pb-0 pt-3 text-center text-lg"
            lineClamp={2}
          >
            {stud.title}
          </Title>
          <Divider my="xs" color="Color2" variant="dashed" />
          <Text
            c="Color3"
            fw={200}
            size="xs"
            lineClamp={5}
            className="!px-3 !text-justify"
          >
            {HtmlToStr(stud.excerpt, 200)}
          </Text>
        </Link>
        <Divider my="xs" color="Color2" variant="line" />
        <Grid gutter="md" justify="center" align="flex-start">
          <Grid.Col span={{ base: 12, xs: 12, sm: 6, md: 6, lg: 6 }}>
            <Button
              className="!w-[100%]"
              variant="outline"
              color="Color4"
              // c={"Color3"}
              onClick={() => {
                open();
                setSelectedStudie(stud.title);
              }}
            >
              طلب الدراسة
            </Button>
          </Grid.Col>
          <Grid.Col span={{ base: 12, xs: 12, sm: 6, md: 6, lg: 6 }}>
            <Button
              className="!w-[100%] font-bold"
              variant="filled"
              // size="sm"
              component={Link}
              href={`/${decodeURIComponent(stud.slug)}`}
              color="Color3"
            >
              تفاصيل الدراسة
            </Button>
          </Grid.Col>
        </Grid>
      </Card>
    </Grid.Col>
  );
}
