import { useQuery } from "@tanstack/react-query";
import { request } from "graphql-request";

import config from "../../config";

// import LoadingStudies from "@/components/LoadingStudies/LoadingStudies";
const GRAPHQL_ENDPOINT = `${config.API_HOST}apigrahql`;

export const useQueryApi = (
  query: string,
  queryKey: any,
  options?: any,
  variables?: any
) => {
  return useQuery({
    queryKey: queryKey,
    queryFn: async () =>
      request(GRAPHQL_ENDPOINT, query, {
        ...variables,
        headers: { Authorization: "Bearer YOUR_TOKEN" }, // Pass headers to the request
      }),
    ...options,
    // enabled: false,
    networkMode: "offlineFirst",
    retry: 3,
    retryOnMount: false,
    refetchOnMount: false,
    refetchOnWindowFocus: "always",
    refetchOnReconnect: false,
    // notifyOnChangeProps: ["data"],
    throwOnError: true,
    // initialData: () => {
    //   return <LoadingStudies />;
    // },
  });
};
