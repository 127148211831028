import Link from "next/link";
import { Key, useState } from "react";

import { Button, Center, Grid, Text, Title } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

import Loaders from "@/components/Loaders/loaders";
import ModalStudie from "@/components/ModalStudie/ModalStudie";
import StudieCard from "@/components/StudieCard/StudieCard";
import { useQueryApi } from "@/libs/utils/useQueryApi";

export default function LatestStudie() {
  const [opened, { open, close }] = useDisclosure(false);
  const [selectedStudie, setSelectedStudie] = useState(null);

  const queryPostsF = `{
        posts(first: 8, where: {status: PUBLISH, categoryId: 5}) {
          nodes {
            title
            slug
            excerpt
            nextjs {
              rate
            }
            featuredImage {
              node {
                sourceUrl(size: MEDIUM_LARGE)
              }
            }
          }
        }
      }`;

  const {
    data: latestStudie,
    isLoading,
    error,
  }: {
    data: any;
    isLoading: boolean;
    error: any;
  } = useQueryApi(queryPostsF, ["latestStudie"], {
    refetchInterval: 60000,
  });

  if (isLoading || latestStudie === undefined) {
    return <Loaders />;
  } else if (error) {
    return (
      <div className="content-center items-center text-center">
        آعد تحميل الصفحه!
      </div>
    );
  } else {
    return (
      <>
        <Title size={"h1"} order={4} c="Color4" className="homeHead">
          آهم الدراسات
        </Title>
        <Text c={"Color3"} className="!pb-16 text-center !text-lg">
          تعرف على أحدث الدراسات التي قمنا بتنفيذها للمشاريع المختلفة حول الوطن
          العربي
        </Text>

        <Grid gutter="xl" justify="center" align="flex-start">
          {latestStudie &&
            latestStudie?.posts?.nodes?.map(
              (stud: any, index: Key | null | undefined) => (
                <StudieCard
                  key={index}
                  i={index as number}
                  stud={stud}
                  open={open}
                  setSelectedStudie={setSelectedStudie}
                  heading={4}
                />
              )
            )}
        </Grid>
        <ModalStudie
          opened={opened}
          close={close}
          selectedStudie={selectedStudie}
        />
        <Center w={"100%"} h={"100%"} className="!py-10">
          <Button
            variant="filled"
            color="Color3"
            component={Link}
            href="/studies"
            aria-label="المزيد من الدراسات"
          >
            المزيد من الدراسات؟
          </Button>
        </Center>
      </>
    );
  }
}
