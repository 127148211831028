import { Container } from "@mantine/core";
import { IconUserPlus } from "@tabler/icons-react";
import ScrollAnimation from "react-animate-on-scroll";

import Counter from "@/components/Counter/Counter";

export function StatsHome() {
  // make a empty map for 4 items in loop

  const items = [
    {
      title: "سنوات الخبره",
      number: 6,
      icons: IconUserPlus,
    },
    {
      title: "دول نقدم بها خدمتنا",
      number: 22,
      icons: IconUserPlus,
    },
    {
      title: "درسات تم تقديمها",
      number: 371,
      icons: IconUserPlus,
    },
    {
      title: "عملاء لدينا",
      number: 120,
      icons: IconUserPlus,
    },
  ];

  return (
    <Container
      fluid
      className={
        "content-center items-center justify-center bg-Color4 xxs:!px-5 lg:!py-5"
      }
    >
      <div className="mx-auto max-w-screen-xl px-4 py-8 xxs:!px-0 sm:px-6 sm:py-12 lg:px-8">
        <div className="mx-auto max-w-3xl text-center">
          <h5 className="homeHead text-white">احصائيات وأرقام</h5>

          <p className="mt-4 text-Color1 sm:text-xl">
            موثوقين لدي العديد من العملاء، ولدينا من الخبره والدراسه ما يكفي
            لاشباع جميع رغبات عملائنا
          </p>
        </div>

        <div
          dir="ltr"
          className="mg-6 grid grid-cols-1 gap-4 divide-y divide-gray-100 sm:mt-8 sm:grid-cols-2 sm:divide-x sm:divide-y-0 lg:grid-cols-4"
        >
          {items.map((item, index) => (
            <ScrollAnimation
              animateOnce={true}
              delay={(index + 3) * 100}
              animateIn="slideInUp"
              animateOut="slideOutUp"
              key={index}
            >
              <div className="flex flex-col px-4 py-8 text-center">
                <div className="order-last text-lg font-medium text-Color1">
                  {item.title}
                </div>

                <div className="text-4xl font-extrabold text-white md:text-5xl">
                  <span className="text-Color3">+</span>
                  <Counter number={item.number} duration={50} />
                </div>
              </div>
            </ScrollAnimation>
          ))}
        </div>
      </div>
    </Container>
  );
}
