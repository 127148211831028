"use client";

import Image from "next/image";
import Link from "next/link";
import {
  AwaitedReactNode,
  JSXElementConstructor,
  Key,
  ReactElement,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";

import { Carousel } from "@mantine/carousel";
import "@mantine/carousel/styles.css";
import { rem } from "@mantine/core";
import { Overlay, Text } from "@mantine/core";
import {
  IconArrowBigLeftLinesFilled,
  IconArrowBigRightLinesFilled,
} from "@tabler/icons-react";
import Autoplay from "embla-carousel-autoplay";

import ExtractSRC from "@/app/_utils/ExtractSRC";
import Loaders from "@/components/Loaders/loaders";
import config from "@/config";

export default function HomeCarousel() {
  const autoplay = useRef(Autoplay({ delay: 4000 }));

  const [carouselData, setCarouselData] = useState<null | any>(null);

  useEffect(() => {
    async function fetchPosts() {
      const queryObjects = `
        {
          categories(first: 100, where: {exclude: [1,5], order: DESC, orderby: COUNT}) {
            nodes {
              slug
              name
              description
            }
          }
        }
    `;
      const fetchCat = await fetch(
        `${config.API_HOST}apigrahql?query=${queryObjects}`,
        {
          cache: "no-cache",
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + config.API_KEY,
          },
        }
      ).then((res) => res.json());
      setCarouselData(fetchCat);
    }
    fetchPosts();
  }, []);
  if (!carouselData) return <Loaders />;
  return (
    <div dir="ltr">
      <Carousel
        loop
        dragFree
        withIndicators
        height={200}
        plugins={[autoplay.current]}
        onMouseEnter={autoplay.current.stop}
        onMouseLeave={autoplay.current.reset}
        nextControlIcon={
          <IconArrowBigRightLinesFilled
            aria-label="السابق"
            style={{ width: rem(40), height: rem(40) }}
          />
        }
        previousControlIcon={
          <IconArrowBigLeftLinesFilled
            aria-label="التالي"
            style={{ width: rem(40), height: rem(40) }}
          />
        }
        slideSize={{ base: "100%", sm: "48.3%", md: "32%", lg: "23.9%" }}
        slideGap={{ base: 0, sm: "md" }}
        align="start"
        // classNames={classes}
        classNames={{
          root: "relative",
          slide: "relative overflow-hidden me-0 ms-0 mr-[1%] rounded-lg group",
          container: "relative",
          viewport: "relative",
          control:
            "!bg-transparent !border-0 !text-Color4 !shadow-none hover:!text-Color1",
          indicator:
            "w-[12px] h-[8px] transition-all duration-300 !bg-Color1 [&[data-active]]:w-[50px] [&[data-active]]:!bg-Color4",
        }}
      >
        {carouselData &&
          carouselData?.data?.categories?.nodes.map(
            (
              cat: {
                slug: any;
                name:
                  | string
                  | number
                  | bigint
                  | boolean
                  | ReactElement<any, string | JSXElementConstructor<any>>
                  | Iterable<ReactNode>
                  | Promise<AwaitedReactNode>
                  | null
                  | undefined;
                description: string;
              },
              index: Key | null | undefined
            ) => (
              <Carousel.Slide key={index}>
                <Link
                  href={`/studies/${cat.slug}`}
                  key={index}
                  className={"!absolute h-full w-full"}
                >
                  <Overlay
                    color="#000"
                    opacity={0.6}
                    // blur={5}
                    radius="md"
                    zIndex={1}
                    className={
                      "opacity-60 transition-all duration-500 ease-in-out group-hover:bg-transparent group-hover:opacity-10"
                    }
                  />
                  <Text
                    className={
                      "absolute left-[50%] top-[50%] !z-[9999] w-auto translate-x-[-50%] translate-y-[-50%] justify-center text-nowrap !rounded-md !px-5 !py-3 text-center !text-lg !font-bold !text-Color1 transition-all duration-300 group-hover:bg-Color4Darken20"
                    }
                    c={"white"}
                  >
                    {typeof cat.name === "string" ? cat.name : ""}
                  </Text>
                  <Image
                    fill
                    sizes="(max-width: 480px) 5vw, (max-width: 800px) 10vw, (max-width: 2048px) 10vw, 10vw"
                    src={ExtractSRC(cat?.description) || ""}
                    style={{ objectFit: "cover" }}
                    alt={typeof cat.name === "string" ? cat.slug : ""}
                    loading="lazy"
                    placeholder="blur"
                    blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                  />
                </Link>
              </Carousel.Slide>
            )
          )}
      </Carousel>
    </div>
  );
}
